import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress from '@mui/material/LinearProgress';
import ConfigurableList from '../components/TextEditor';


export default function DataGridDemo() {

  interface textEditorConfigItem {
    systemName: string;
    name: string;
    value: any;
  }

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'id', headerName: 'ID', width: 100},
    { field: 'agreementId', headerName: 'AgreementId', width: 100},
    {
      field: 'actual',
      headerName: 'Actual v.',
      width: 80,
      renderCell: (params) => (
        <>
        {params.row.actual ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : ''}
        </>
      ),
    },
    {
      field: 'last',
      headerName: 'Last v.',
      width: 80,
      renderCell: (params) => (
        <>
        {params.row.last ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : ''}
        </>
      ),
    },
    { field: 'name', headerName: 'Name', width: 400 },
    { field: 'actualVersion', headerName: 'Version', width: 80 },
    {
      field: 'purposes',
      headerName: 'Purposes',
      width: 300,
      renderCell: (params:any) => (
        <div>
        {/* Render your array data in a way that fits your UI */}
        {params.value?.map((value:any) => (
          <div key={value.code}>{value.name} </div>
        ))}
      </div>
      ),
    },
  ];

  const columnsPurposes: GridColDef[] = [
    { field: 'code', headerName: 'Code', width: 65},
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 190 },
    { field: 'category', headerName:'Category', width: 200},
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDeleteClick(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const [textEditorConfig, setTextEditorConfig] = useState( [
    { systemName: 'common.back', name: 'Tlačítko zpět', value:'' },
    { systemName: 'common.confirmChanges', name: 'Tlačítko potvrdit změny', value:'' },
    { systemName: 'common.cancelChanges', name: 'Tlačítko zrušit změny', value:'' },
    { systemName: 'common.display', name: 'Tlačítko zobrazit', value:'' },
    { systemName: 'common.settings', name: 'Tlačítko nastavení', value:'' },
    { systemName: 'common.confirmSettings', name: 'Tlačítko potvrdit nastavení', value:'' },
    { systemName: 'home.content', name: 'Obsah CB nahoře', value:'' },
    { systemName: 'home.actions.allow', name: 'Tlačítko souhlasím', value:'' },
    { systemName: 'home.actions.reject', name: 'Tlačítko nesouhlasím', value:'' },
    { systemName: 'home.actions.settings', name: 'Tlačítko upravit nastavení', value:'' },
    { systemName: 'footer.content', name: 'Obsah CB dole', value:'' },
    { systemName: 'footer.purposesMore', name: 'Obsah CB dole shrnutí účely', value:'' },
    { systemName: 'footer.purposesExact', name: 'Obsah CB dole účely shrnutí', value:'' },
    { systemName: 'footer.purposesCount', name: 'Obsah CB dole účely počet', value:'' },
    { systemName: 'categoriesSettings.title', name: 'Nastavení - tituel', value:'' },
    { systemName: 'categoriesSettings.content', name: 'Nastavení - obsah', value:'' },
    { systemName: 'categoriesSettings.required.subtitle', name: 'Nastavení - povinné - podtitulek', value:'' },
    { systemName: 'categoriesSettings.required.count', name: 'Nastavení - povinné - počet', value:'' },
    { systemName: 'categoriesSettings.required.name', name: 'Nastavení - povinné - jméno', value:'' },
    { systemName: 'categoriesSettings.required.description', name: 'Nastavení - povinné - popis', value:'' },
    { systemName: 'categoriesSettings.other.subtitle', name: 'Nastavení - další - podtitulek', value:'' },
    { systemName: 'categoriesSettings.other.countDesktop', name: 'Nastavení - další - počet na desktopu', value:'' },
    { systemName: 'categoriesSettings.other.countMobile', name: 'Nastavení - další - počet na mobile', value:'' },
    { systemName: 'categorySettings.content', name: 'Nastavení kategorie - obsah', value:'' },
    { systemName: 'categorySettings.allowed', name: 'Nastavení kategorie - povolené', value:'' },
    { systemName: 'categoryRequired.title', name: 'Katerorie - tituelk', value:'' },
    { systemName: 'categoryRequired.label', name: 'Kategorie - text', value:'' },
    { systemName: 'categoryRequired.cookiesCount', name: 'Kategorie - počet cookies', value:'' },
    { systemName: 'categoryRequired.content', name: 'Kategorie - obsah', value:'' },
  ]);

    const [open, setOpen] = useState(false);
    const [openSubDialog, setOpenSubDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertText, setOpenAlertText] = useState('Cannot add item, because it is already added.');


    function createTextJsonString(inputArray: textEditorConfigItem[]): string {
      const resultObject: { [key: string]: any } = {};

      inputArray.forEach(item => {
        resultObject[item.systemName] = item.value;
      });
    
      return JSON.stringify(resultObject, null, 2);
    }

    function parseTextJson(jsonString: string, collection: textEditorConfigItem[]): textEditorConfigItem[] {

    
      // Create a copy of the collection to avoid mutating the original array
      const updatedCollection = collection.map(item => ({ ...item }));
    
      if (jsonString === undefined) return updatedCollection;

      const jsonObject = JSON.parse(jsonString);

      // Update the values in the collection based on the JSON input
      updatedCollection.forEach(item => {
        if (jsonObject.hasOwnProperty(item.systemName)) {
          item.value = jsonObject[item.systemName];
        }
      });
    
      return updatedCollection;
    }


    // OPEN DIALOG
    const handleOpen = (props:any) => {
      setItem(props);
      console.log(props.template)
      setTextEditorConfig(parseTextJson(props.template, textEditorConfig));
      console.log(textEditorConfig);
      setOpen(true);
    };

    //CLOSE DIALOG
    const handleClose = () => {
      setOpen(false);
    };

    //SAVE DIALOG
    const handleSave = async(operationEnum:string) => {
 
      const itemToSave = item;
      //transform text editor to template json
      itemToSave.template = createTextJsonString(textEditorConfig);
      itemToSave.operationEnum = operationEnum;

      console.log(itemToSave);

      const token = await getAccessTokenSilently();
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + token },
        body: JSON.stringify(itemToSave)
        };
      await fetch(process.env.REACT_APP_AGREEMENT_URL, requestOptions);

      loadData();
      setOpen(false);
    }

    // OPEN ADD PURPOSE DIALOG
    const addPurpose = async() => {
      setAddPurposeItem('');
      setOpenSubDialog(true);
    };

    // CANCEL PURPOSE DIALOG
    const handlePurpleClose = () => {
      setOpenSubDialog(false);
    };

    // SAVE ADD PURPOSE DIALOG
    const handleAddPurpose = () => {

      //check if added item is not already added
      if (item.purposes.filter((item:any) => item.code === addPurposeItem).length > 0)
      {
        setOpenAlertText('Cannot add item, because it is already added.')
        setOpenAlert(true);
        return;
      }

      const selectedPurposeCodebook:any = codebookPurposes.find((i:any) => i.code === addPurposeItem);
      console.log(selectedPurposeCodebook)
      const newPurposes = [...item.purposes, {id:addPurposeItem, code:addPurposeItem, name:selectedPurposeCodebook.name, description:selectedPurposeCodebook.description, category:selectedPurposeCodebook.additionalFields[0]} as never];
      setItem((prevState:any) => ({
        ...prevState,
          purposes: newPurposes
      }))
      setOpenSubDialog(false);
    };

    //DELETE PURPOSE LINE IN GRID
    const handleDeleteClick = (id: number) => {
      // Delete logic: Filter out the item with the specified ID
      const updatedPurposes = item.purposes.filter((item:any) => item.id !== id);
      setItem((prevState:any) => ({
        ...prevState,
          purposes: updatedPurposes
      }))
    };
    

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [codebookAgreementTypes, setCodebookAgreementTypes] = useState([]);
    const [codebookPurposes, setCodebookPurposes] = useState([]);
    const [addPurposeItem, setAddPurposeItem] = useState('');
  
    const [search, setSearch] = useState('');
    const [onlyActual, setOnlyActual] = React.useState(true);

    const [item, setItem] = useState(Object);
    
    const [tabValue, setTabValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };

    
    //PRESS ENTER IN SEARCH TEXT BOX
    const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData();
      }
    };


    const changeSearch = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setSearch(event.target.value)
    }

    const handleTypeChange = (event: SelectChangeEvent) => {
      setItem((prevState:any) => ({
        ...prevState,
          type: event.target.value,
      }));

    };

    // eslint-disable-next-line
    const loadData = async() => {

        console.log("button clicked "+search);

        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_AGREEMENTS_URL +"?search="+search+"&onlyActualOrLast="+onlyActual, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (response.status) {
            const agreements = await response.json();
            if (agreements != null)
            {
              setTableData(agreements);

            }
            console.log("data loaded.")
          }
    };
    
    useEffect(() => {

      const loadCodebooks = async() => {

          const token = await getAccessTokenSilently();

          const response = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C003", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response.status) {
              const codebook = await response.json();
              if (codebook != null)
                setCodebookAgreementTypes(codebook.items);
              console.log("data loaded.")
            }
          const response2 = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C002", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response2.status) {
              const codebook2 = await response2.json();
              if (codebook2 != null)
                {
                  const filteredOptions = codebook2.items.filter((i:any) => i.active === true);
                  setCodebookPurposes(filteredOptions.slice().sort((a:any, b:any) => a.additionalFields[0].localeCompare(b.additionalFields[0])));
                }
                
              console.log("data loaded.")
            }
  
      };

      loadData();
      loadCodebooks();
    // eslint-disable-next-line
    }, [])
   
  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Button variant="contained" onClick={() => handleOpen({id:'new', actualVersion:"0.0", purposes:[], type:''})} sx={{pr:2}}>New agr.</Button>
                <TextField label="Search" id="txtSearch" value={search} onChange={changeSearch} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "600px" }}}
                   />
                <FormControlLabel control={<Checkbox checked={onlyActual} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOnlyActual(event.target.checked)} />} label="Only actual or last versions" sx={{pl:8}} />
                <Button variant="contained" onClick={loadData}>Search</Button>
            </div> 
            <Box sx={{ width: '100%' }}>
                { tableData.length === 0 ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
      </Box>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 1000,
            maxHeight: 1000
          }
        }}
      >
      <DialogTitle>Agreement {item.id}</DialogTitle>
        <DialogContent dividers style={{height:'1000px'}}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Agreement" value="1" />
              <Tab label="Abstract" value="2" />
              <Tab label="Content" value="3" />
              <Tab label="Cookie bot - text editor" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TextField
                    helperText="Name"
                    placeholder="Name"
                    value={item.name}
                    fullWidth 
                    onChange={(event:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    name: event.target.value,
                                                }))}
                />
            <Grid container spacing={2} columns={16}>
              <Grid item xs={6}>
              <TextField
                    helperText="Actual version"
                    placeholder="Actual version"
                    value={item.actualVersion}
                    fullWidth 
                    disabled
                />
              </Grid>
              <Grid item xs={6}>
              <TextField
                    helperText="Forced version"
                    placeholder="Forced version"
                    value={item.forceVersion}
                    fullWidth
                    disabled
                />
              </Grid>
              <Grid item xs={4} >
              <FormControlLabel control={<Checkbox checked={item.forceUpdate} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setItem((prevState:any) => ({
                            ...prevState,
                              forceUpdate: event.target.checked,
                    }))} />} label="Force update of con." sx={{pl:1}} />
              </Grid>
              <Grid item xs={12}>
                  <FormControl sx={{minWidth: 500 }} >
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={item.type}
                    fullWidth
                    label="Type"
                    onChange={handleTypeChange}
                  >
                    {codebookAgreementTypes.map((option:any) => (
                        <MenuItem value={option.code} key={option.code}>
                          {option.name}
                        </MenuItem>
                    ))}

                  </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={4} sx={{ alignSelf:'center'}}>
                <FormControlLabel control={<Checkbox checked={item.changePurpose}                     onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                        changePurpose: event.target.checked,
                      }))} />} label="Can change purposes" sx={{pl:1}} />
              </Grid>
              <Grid item xs={8}>
                  <FormControlLabel control={<Checkbox checked={item.enableGCM2}                     onChange={(event:any) => setItem((prevState:any) => ({
                            ...prevState,
                            enableGCM2: event.target.checked,
                          }))} />} label="Enable Google Consent Mode" sx={{pl:1}} />
              </Grid>
              <Grid item xs={8}>
              <FormControlLabel control={<Checkbox checked={item.enableGCM2Advanced}                     onChange={(event:any) => setItem((prevState:any) => ({
                            ...prevState,
                            enableGCM2Advanced: event.target.checked,
                          }))} />} label="Allow Google tags to run before consent" sx={{pl:1}} />
              </Grid>
            </Grid>
            <Link href={'https://waulter.eu/agreement?agreementVersionId='+item.permalink} target='_blank' underline="none" sx={{borderSpacing:3}} variant='inherit'>
                  {'https://waulter.eu/agreement?ageementVersionId='+item.permalink}
                  </Link>
            <TextField
                    helperText="Description (public)"
                    placeholder="Description (public)"
                    value={item.description}
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(event:any) => setItem((prevState:any) => ({
                      ...prevState,
                        description: event.target.value,
                    }))}
                />
            <Button variant="contained" onClick={addPurpose} disabled={!item.last}>Add</Button>
            <DataGrid
                rows={item.purposes}
                columns={columnsPurposes}
                pageSize={13}
                rowsPerPageOptions={[13]}
                disableSelectionOnClick
                autoHeight={true}
                experimentalFeatures={{ newEditingApi: true }}
          />
          </TabPanel>

          <TabPanel value="2">
            <TextField
                      helperText="Agreement abstract (public content HTML)"
                      placeholder="Agreement abstract (public content HTML)"
                      value={item.abstractText}
                      multiline
                      rows={25}
                      fullWidth
                      onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                          abstractText: event.target.value,
                      }))}
                  />
          </TabPanel>

          <TabPanel value="3">
            <TextField
                      helperText="Agreement content (public content HTML)"
                      placeholder="Agreement content (public content HTML)"
                      value={item.content}
                      multiline
                      rows={25}
                      fullWidth
                      onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                          content: event.target.value,
                      }))}
                  />
          </TabPanel>
          <TabPanel value="4">

            <span>Template ID: </span>

            <Select name="templateID" label="Template ID" sx={{minWith:300}} value={item.templateID}                       
                      onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                          templateID: event.target.value,
                      }))}>
                                <MenuItem key='waulter_default_1' value='waulter_default_1'>Waulter default 1</MenuItem>
                                <MenuItem key='waulter_default_2' value='waulter_default_2'>Waulter default 2</MenuItem>
            </Select> <br/>

            <ConfigurableList config={textEditorConfig}  />

          </TabPanel>
        </TabContext>


        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => handleSave('createMinorVersion')} disabled={!(item.last || item.id==='new') }>Create minor version</Button>
          <Button onClick={() => handleSave('createMajorVersion')} disabled={!(item.last || item.id==='new')}>Create major version</Button>
          <Button onClick={() => handleSave('publishActualVersion')} disabled={!item.last || item.actual}>Publish actual version</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSubDialog}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1020px!important",
            heigh: "1000px",
            maxHeight:"1000px!important"
          },
        }}
      >
      <DialogTitle>Agreement {item.id}</DialogTitle>
        <DialogContent dividers>
          <FormControl sx={{ m: 1, minWidth: 720 }} size="small">
              <InputLabel id="demo-select-small-label">Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={addPurposeItem}
                autoWidth
                label="Type"
                onChange={(event: SelectChangeEvent) => setAddPurposeItem(event.target.value)}
              >
                {codebookPurposes.filter((i:any) => (i.active===true)).map((option:any) => (
                    <MenuItem value={option.code} key={option.code}>
                      {option.additionalFields[0]} - {option.name}
                    </MenuItem>
                ))}

              </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleAddPurpose}>Add</Button>
          <Button onClick={handlePurpleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAlert} onClose={() => setOpenAlert(false)}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {openAlertText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}