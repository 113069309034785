import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import UserSelectDialog from '../components/UserSelectDialog';
import moment from "moment";

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'id', headerName: 'ID', width: 140},
    { field: 'notBefore', headerName: 'Accepted', width: 160,   type: 'dateTime',  valueFormatter: params => moment(params?.value).format("DD.MM.YYYY HH:mm:ss") },
    { field: 'notAfter', headerName: 'Expire', width: 160,   type: 'dateTime',  valueFormatter: params => moment(params?.value).format("DD.MM.YYYY HH:mm:ss") },
    { field: 'decision', headerName: 'Decision', width: 80 },
    { field: 'url', headerName: 'Url', width: 250 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'ccid', headerName: 'CCID', width: 80},
    { field: 'agreementId', headerName: 'AgreementId', width: 80},
    { field: 'agreementActualVersion', headerName: 'Actual version', width: 60 },
    { field: 'partnerId', headerName: 'Partner ID', width: 80 },
    {
      field: 'purposes',
      headerName: 'Purposes',
      width: 350,
      renderCell: (params:any) => (
        <div>
        {/* Render your array data in a way that fits your UI */}
        {params.value?.map((value:any) => (
          <div key={value.code}>{value.name} </div>
        ))}
      </div>
      ),
    },
  ];

  const columnsPurposes: GridColDef[] = [
    { field: 'code', headerName: 'Code', width: 65},
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 190 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDeleteClick(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

    const [open, setOpen] = useState(false);
    const [openSubDialog, setOpenSubDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertText, setOpenAlertText] = useState('Cannot add item, because it is already added.');
    const [loading, setLoading] = useState(false);

    // OPEN DIALOG
    const handleOpen = (props:any) => {
      setItem(props);
      setOpen(true);
    };

    //CLOSE DIALOG
    const handleClose = () => {
      setOpen(false);
    };

    //SAVE DIALOG
    const handleSave = async(operationEnum:string) => {
 
      const itemToSave = item;
      itemToSave.operationEnum = operationEnum;

      console.log(itemToSave);

      const token = await getAccessTokenSilently();
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + token },
        body: JSON.stringify(itemToSave)
        };
      await fetch(process.env.REACT_APP_AGREEMENT_URL, requestOptions);

      loadData();
      setOpen(false);
    }

    // OPEN ADD PURPOSE DIALOG
    const addPurpose = async() => {
      setAddPurposeItem('');
      setOpenSubDialog(true);
    };

    // CANCEL PURPOSE DIALOG
    const handlePurpleClose = () => {
      setOpenSubDialog(false);
    };

    // SAVE ADD PURPOSE DIALOG
    const handleAddPurpose = () => {

      //check if added item is not already added
      if (item.purposes.filter((item:any) => item.code === addPurposeItem).length > 0)
      {
        setOpenAlertText('Cannot add item, because it is already added.')
        setOpenAlert(true);
        return;
      }

      const selectedPurposeCodebook:any = codebookPurposes.find((i:any) => i.code === addPurposeItem);
      console.log(selectedPurposeCodebook)
      const newPurposes = [...item.purposes, {id:addPurposeItem, code:addPurposeItem, name:selectedPurposeCodebook.name, description:selectedPurposeCodebook.description} as never];
      setItem((prevState:any) => ({
        ...prevState,
          purposes: newPurposes
      }))
      setOpenSubDialog(false);
    };

    //DELETE PURPOSE LINE IN GRID
    const handleDeleteClick = (id: number) => {
      // Delete logic: Filter out the item with the specified ID
      const updatedPurposes = item.purposes.filter((item:any) => item.id !== id);
      setItem((prevState:any) => ({
        ...prevState,
          purposes: updatedPurposes
      }))
    };
    

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [codebookAgreementTypes, setCodebookAgreementTypes] = useState([]);
    const [codebookPurposes, setCodebookPurposes] = useState([]);
    const [addPurposeItem, setAddPurposeItem] = useState('');
  
    const [consentizerId, setConsentizerId] = useState('');
    const [partnerId, setPartnerId] = React.useState('');

    const [item, setItem] = useState(Object);
    
    const [tabValue, setTabValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };

    
    //PRESS ENTER IN SEARCH TEXT BOX
    const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData();
      }
    };


    const changeConsentizerId = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setConsentizerId(event.target.value)
    }

    const changePartnerId = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setPartnerId(event.target.value)
    }   

    const handleTypeChange = (event: SelectChangeEvent) => {
      setItem((prevState:any) => ({
        ...prevState,
          type: event.target.value,
      }));

    };

    const userSelected = (selectedItem: string) => {
      // Handle the selected item
      if (selectedItem !== '')
        setConsentizerId(selectedItem);
    };

    // eslint-disable-next-line
    const loadData = async() => {

        console.log("button clicked ");
        setLoading(true);
        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_ACCEPTANCES_URL +"?consentizerId="+consentizerId+"&partnerId="+partnerId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (response.status) {
            const agreements = await response.json();
            if (agreements != null)
              setTableData(agreements);
            console.log("data loaded.")
          }
        setLoading(false);
    };
    
    useEffect(() => {

      const loadCodebooks = async() => {

          const token = await getAccessTokenSilently();

          const response = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C003", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response.status) {
              const codebook = await response.json();
              if (codebook != null)
                setCodebookAgreementTypes(codebook.items);
              console.log("data loaded.")
            }
          const response2 = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C002", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response2.status) {
              const codebook2 = await response2.json();
              if (codebook2 != null)
                setCodebookPurposes(codebook2.items);
              console.log("data loaded.")
            }
  
      };

      loadData();
      loadCodebooks();
    // eslint-disable-next-line
    }, [])
   
  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <TextField label="ConsentizerId" id="txtSearch" value={consentizerId} onChange={changeConsentizerId} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "600px" }}}
                   />
                <UserSelectDialog
                  onItemSelected={userSelected}
                />
                <TextField label="PartnerId" id="txtPartnerId" value={partnerId} onChange={changePartnerId} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "600px" }}}
                   />
                <Button variant="contained" onClick={loadData}>Search</Button>
            </div> 
            <Box sx={{ width: '100%' }}>
                { loading ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
      </Box>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 1000,
            maxHeight: 900
          }
        }}
      >
      <DialogTitle>Acceptance {item.id}</DialogTitle>
        <DialogContent dividers style={{height:'900px'}}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Agreement" value="1" />
              <Tab label="Content" value="2" />
              <Tab label="Template" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TextField
                    helperText="Name"
                    placeholder="Name"
                    value={item.name}
                    fullWidth 
                    onChange={(event:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    name: event.target.value,
                                                }))}
                />
            <Grid container spacing={2} columns={16}>
              <Grid item xs={6}>
              <TextField
                    helperText="Actual version"
                    placeholder="Actual version"
                    value={item.actualVersion}
                    fullWidth 
                    disabled
                />
              </Grid>
              <Grid item xs={6}>
              <TextField
                    helperText="Forced version"
                    placeholder="Forced version"
                    value={item.forceVersion}
                    fullWidth
                    disabled
                />
              </Grid>
              <Grid item xs={4} >
              <FormControlLabel control={<Checkbox checked={item.forceUpdate} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setItem((prevState:any) => ({
                            ...prevState,
                              forceUpdate: event.target.checked,
                    }))} />} label="Force update of con." sx={{pl:1}} />
              </Grid>
              <Grid item xs={6}>
                  <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={item.type}
                    autoWidth
                    label="Type"
                    onChange={handleTypeChange}
                  >
                    {codebookAgreementTypes.map((option:any) => (
                        <MenuItem value={option.code} key={option.code}>
                          {option.name}
                        </MenuItem>
                    ))}

                  </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ alignSelf:'center'}}>
                    <Link href="#" underline="none">
                  {'https://waulter.eu/contract.html?id='+item.permalink}
                  </Link>
              </Grid>
              <Grid item xs={4} sx={{ alignSelf:'center'}}>
                <FormControlLabel control={<Checkbox checked={item.changePurpose}                     onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                        changePurpose: event.target.checked,
                      }))} />} label="Can change purposes" sx={{pl:1}} />
              </Grid>
            </Grid>
            <TextField
                    helperText="Description (public)"
                    placeholder="Description (public)"
                    value={item.description}
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(event:any) => setItem((prevState:any) => ({
                      ...prevState,
                        description: event.target.value,
                    }))}
                />
            <Button variant="contained" onClick={addPurpose}>Add</Button>
            <DataGrid
                rows={item.purposes}
                columns={columnsPurposes}
                pageSize={13}
                rowsPerPageOptions={[13]}
                disableSelectionOnClick
                autoHeight={true}
                experimentalFeatures={{ newEditingApi: true }}
          />
          </TabPanel>

          <TabPanel value="2">
            <TextField
                      helperText="Agreement content (public content HTML)"
                      placeholder="Agreement content (public content HTML)"
                      value={item.content}
                      multiline
                      rows={25}
                      fullWidth
                      onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                          content: event.target.value,
                      }))}
                  />
          </TabPanel>
          <TabPanel value="3">
            <TextField
                      helperText="Template reference (visual)"
                      placeholder="Template reference (visual)"
                      value={item.template}
                      multiline
                      rows={25}
                      fullWidth
                      onChange={(event:any) => setItem((prevState:any) => ({
                        ...prevState,
                          template: event.target.value,
                      }))}
                  />
          </TabPanel>
        </TabContext>


        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => handleSave('createMinorVersion')}>Create minor version</Button>
          <Button onClick={() => handleSave('createMajorVersion')}>Create major version</Button>
          <Button>Publish actual version</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSubDialog}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1020px!important",
            heigh: "900px",
            maxHeight:"900px!important"
          },
        }}
      >
      <DialogTitle>Agreement {item.id}</DialogTitle>
        <DialogContent dividers>
          <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
              <InputLabel id="demo-select-small-label">Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={addPurposeItem}
                autoWidth
                label="Type"
                onChange={(event: SelectChangeEvent) => setAddPurposeItem(event.target.value)}
              >
                {codebookPurposes.map((option:any) => (
                    <MenuItem value={option.code} key={option.code}>
                      {option.name}
                    </MenuItem>
                ))}

              </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleAddPurpose}>Add</Button>
          <Button onClick={handlePurpleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAlert} onClose={() => setOpenAlert(false)}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {openAlertText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}